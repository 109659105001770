import { graphql, Link } from "gatsby"
import React from "react"
// import { makeBlogPath } from "../utils"
// import dateformat from "dateformat"
import moment from "moment";
import SEOTAG from '../components/seopagetagcat'
import Layout from '../components/Layout'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NewsLists from '../templates/newlists';
const baseuri = "https://dmf5.xyz"
class PostAuthor extends React.Component {

  render() {
    const { data, location } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const domainName = data.site.siteMetadata.domainName
    const posts = data.privateGraphql.postbyauthor
    const author = data.privateGraphql.getauthor
    const { currentPage, numPagesa } = this.props.pageContext
    const authorname = author.profilename;
    const bio = author.bio;
    let curr = 1;
    if (currentPage === undefined) { curr = 1 }
    else { curr = currentPage }
    const isFirst = curr === 1
    const isLast = curr === numPagesa
    const prevPage = curr - 1 === 1 ? '/' : (curr - 1).toString()
    const nextPage = (curr + 1).toString()
    if (location != null) {
      if (location.href != null && location.href.endsWith('/')) {
        window.location.href = window.location.href.substring(0, window.location.href.length - 1);
      }
    }
    return (
      <Layout location={this.props.location} title={authorname}>
        <SEOTAG
          title={`${authorname}`}
          description={`${bio}`}
          canonical={`https://${domainName}/authors/${author.slugname}`}
          authorName={authorname}
          authordescription={bio}
          authorimage={baseuri + author.image}
          authorSocial={author.twitter}
        />
        <div className="w-layout-container">
          <header className="w-page-header">
            <h1 className="w-page-header__headline">{authorname}</h1>
            <p className="w-page-header__copy">
              {author.biowithhtml ?
                <section dangerouslySetInnerHTML={{ __html: author.biowithhtml }} />
                :
                bio
              }
            </p>
          </header>
        </div>
        <ul className="row feature-list feature-list-sm">
          {posts.map(node => {
            const title = node.title || node.slugtitle
            return (
              <NewsLists key={node.slugtitle} node={node} />
            )
          })}
          <li className="col-12 text-center w-pagination">
            <ul
            >
              {!isFirst && (
                <li className="w-pagination__link">
                  <Link to={curr <= 2 ? `/authors/${author.slugname}` : `/authors/${author.slugname}/page/${prevPage}`} rel="prev">
                    ←
                </Link>
                </li>
              )}
              {Array.from({ length: numPagesa }, (_, i) => {
                if (i < curr + 3 && i > curr - 3) {
                  return (
                    <li
                      key={`pagination-number${i + 1}`}
                      className="w-pagination__link"
                    >
                      <Link
                        to={`/authors/${author.slugname}${i === 0 ? '' : '/page/' + (i + 1)}`}
                        style={{
                          textDecoration: 'none',
                          color: i + 1 === curr ? '#000' : '',
                          background: i + 1 === curr ? '#fff' : '',
                        }}
                      >
                        {i + 1}
                      </Link>
                    </li>
                  )
                }
              })}
              {!isLast && (
                <li className="w-pagination__link">
                  <Link to={`/authors/${author.slugname}/page/${nextPage}`} rel="next">
                    →
                </Link>
                </li>
              )}
            </ul>
          </li>
        </ul>
      </Layout>
    )
  }
}
export default PostAuthor
export const query = graphql`
query ($author: String,$limit: Int = 21, $skip: Int = 0, $siteId: Int = 1051) {
  site {
    siteMetadata {
      title
      domainName
      description
    }
  }
  privateGraphql {
    getauthor(slugtitle: $author, siteId: $siteId) {
      id
      profilename
      slugname
      image
      bio
      twitter
      biowithhtml
    }
    postbyauthor(
      slugtitle: $author
      limit: $limit 
      skip: $skip
      siteId: $siteId
      ) {
        id
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
        profileImage
    }
  }
}
`
