import * as React from 'react';
import { graphql, Link } from "gatsby"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import moment from "moment";
const baseuri = "https://dmf5.xyz"
const baseuridemo = "http://admin.hovinx.com"


export default function NewLists({ node }) {

    return (
        <li className="col-12 col-md-6 col-lg-4" key={node.slugtitle}>
            <div className="card card-lg">
                <div style={{width:'100%', maxHeight:185}}>
                    <Link to={node.slugtitle} title={node.title}>
                        <LazyLoadImage
                            className="card-img-top"
                            alt={node.title}
                            title={node.title}
                            src={baseuri + node.image}
                            effect="blur"
                        />
                    </Link>
                </div>
                <div className="card-body">
                    <h2 className="card-title mb-1"><Link to={node.slugtitle} title={node.title}>{node.title}</Link></h2>
                    <p className="card-text" dangerouslySetInnerHTML={{ __html: node.short }} />
                </div>
                <div className="card-footer card-footer-borderless">
                    <div className="media blog-post-author">
                        <LazyLoadImage
                            className="avatar avatar-xs"
                            title={node.title}
                            src={baseuri + node.profileImage}
                            effect="blur"
                        />
                        <div className="media-body">
                            <small><Link to={`/authors/${node.profileSlugName}`}>{node.profileName}</Link></small>
                            <small>{moment(node.publishDate).format('DD/MM/YYYY h:mm')}</small>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )

}